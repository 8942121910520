<template>
    <article
        class="h-full rounded-md overflow-hidden box-shadow hover:shadow-lg transition lg:w-auto border border-black border-opacity-10 transition hover:-translate-y-2"
    >
        <nuxt-link
            :to="url"
            rel="noopener noreferrer"
            class="h-full flex w-full"
            :class="{ 'flex-col': md || lg, 'flex-col lg:flex-row': !sm && !md && !lg }"
        >
            <div
                :class="{
                    'lg:w-4/12 border-r border-black border-opacity-5': sm,
                    'w-full lg:w-7/12 border-r border-black border-opacity-5': !sm && !md && !lg,
                    'w-full': md || lg,
                }"
            >
                <div
                    class="h-full min-h-[7.25rem]"
                    :class="{
                        'aspect-w-16 aspect-h-9 min': sm,
                        'aspect-w-16 lg:aspect-w-4 aspect-h-9 lg:aspect-h-3': !xl && !sm,
                        'aspect-w-16 aspect-h-9': xl,
                    }"
                >
                    <img
                        class="object-cover w-full h-full border-b border-black border-opacity-5"
                        :src="imgSrc"
                        :alt="title"
                        data-not-lazy
                    />
                </div>
            </div>
            <div
                class="flex flex-col"
                :class="{
                    'w-full lg:w-8/12 p-3 justify-center': sm,
                    'w-full lg:w-5/12 p-4 md:p-8 justify-between': !sm && !md && !lg,
                    'w-full p-6 justify-between h-full': md,
                    'w-full p-4 justify-between': lg,
                }"
            >
                <div>
                    <h3
                        class="font-Inter-Bold text-blue-100"
                        :class="{ 'mb-1 text-xs': sm || lg, 'mb-5 text-sm': !sm && !md && !lg, 'text-sm mb-3': md }"
                    >
                        {{ category || 'Kategória' }}
                    </h3>
                    <h4
                        class="font-Inter-Bold"
                        :class="{
                            'text-sm mb-4': lg,
                            'text-base mb-4': sm || md,
                            'text-lg lg:text-xl mb-3': !md && !sm && !xl,
                            'text-lg lg:text-xl mb-4': xl,
                        }"
                    >
                        {{ title }}
                    </h4>
                    <h5
                        v-if="!sm && description"
                        class="font-Inter-Regular"
                        :class="{ 'text-xs mb-8 leading-[1.1rem]': md, 'text-sm leading-[1.5]': !md && !sm }"
                    >
                        {{ description }}
                    </h5>
                    <div
                        v-if="!sm && !md && !lg"
                        class="w-full text-blue-100 font-Inter-Bold text-sm mt-8 group mb-8 lg:mb-12"
                        :class="xl ? 'lg:text-right text-xl' : 'lg:text-right text-sm'"
                    >
                        <span class="group-hover:underline" :class="xl ? 'text-sm lg:text-base' : 'text-sm'"
                            >Tovább olvasom</span
                        >
                        <i
                            class="inline-block ri-arrow-right-line transition group-hover:translate-x-1"
                            :class="xl ? 'text-base' : 'text-sm'"
                        ></i>
                    </div>
                </div>
                <div
                    v-if="publishDate"
                    class="text-black opacity-50"
                    :class="[sm ? 'text-[10px]' : 'text-xs', lg && 'mt-4']"
                >
                    <span v-if="!sm && !lg">{{ minutes }} perc olvasás - &nbsp;</span><span>{{ publishDate }}</span>
                </div>
            </div>
        </nuxt-link>
    </article>
</template>

<script>
export default {
    name: 'Card',
    props: {
        url: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        category: {
            type: String,
            required: true,
        },
        description: {
            type: String,
            required: false,
            default: '',
        },
        publishDate: {
            type: String,
            required: true,
        },
        minutes: {
            type: String,
            required: true,
        },
        imgSrc: {
            type: String,
            required: true,
        },
        sm: {
            type: Boolean,
            required: false,
            default: false,
        },
        md: {
            type: Boolean,
            required: false,
            default: false,
        },
        lg: {
            type: Boolean,
            required: false,
            default: false,
        },
        xl: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
};
</script>

<style scoped>
.box-shadow {
    box-shadow: 0 4px 60px 0 rgba(21, 33, 55, 0.08);
    &:hover {
        box-shadow: 0 4px 60px 0 rgba(21, 33, 55, 0.15);
    }
}
</style>
