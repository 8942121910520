var render = function render(){var _vm=this,_c=_vm._self._c;return _c('article',{staticClass:"h-full rounded-md overflow-hidden box-shadow hover:shadow-lg transition lg:w-auto border border-black border-opacity-10 transition hover:-translate-y-2"},[_c('nuxt-link',{staticClass:"h-full flex w-full",class:{ 'flex-col': _vm.md || _vm.lg, 'flex-col lg:flex-row': !_vm.sm && !_vm.md && !_vm.lg },attrs:{"to":_vm.url,"rel":"noopener noreferrer"}},[_c('div',{class:{
                'lg:w-4/12 border-r border-black border-opacity-5': _vm.sm,
                'w-full lg:w-7/12 border-r border-black border-opacity-5': !_vm.sm && !_vm.md && !_vm.lg,
                'w-full': _vm.md || _vm.lg,
            }},[_c('div',{staticClass:"h-full min-h-[7.25rem]",class:{
                    'aspect-w-16 aspect-h-9 min': _vm.sm,
                    'aspect-w-16 lg:aspect-w-4 aspect-h-9 lg:aspect-h-3': !_vm.xl && !_vm.sm,
                    'aspect-w-16 aspect-h-9': _vm.xl,
                }},[_c('img',{staticClass:"object-cover w-full h-full border-b border-black border-opacity-5",attrs:{"src":_vm.imgSrc,"alt":_vm.title,"data-not-lazy":""}})])]),_vm._v(" "),_c('div',{staticClass:"flex flex-col",class:{
                'w-full lg:w-8/12 p-3 justify-center': _vm.sm,
                'w-full lg:w-5/12 p-4 md:p-8 justify-between': !_vm.sm && !_vm.md && !_vm.lg,
                'w-full p-6 justify-between h-full': _vm.md,
                'w-full p-4 justify-between': _vm.lg,
            }},[_c('div',[_c('h3',{staticClass:"font-Inter-Bold text-blue-100",class:{ 'mb-1 text-xs': _vm.sm || _vm.lg, 'mb-5 text-sm': !_vm.sm && !_vm.md && !_vm.lg, 'text-sm mb-3': _vm.md }},[_vm._v("\n                    "+_vm._s(_vm.category || 'Kategória')+"\n                ")]),_vm._v(" "),_c('h4',{staticClass:"font-Inter-Bold",class:{
                        'text-sm mb-4': _vm.lg,
                        'text-base mb-4': _vm.sm || _vm.md,
                        'text-lg lg:text-xl mb-3': !_vm.md && !_vm.sm && !_vm.xl,
                        'text-lg lg:text-xl mb-4': _vm.xl,
                    }},[_vm._v("\n                    "+_vm._s(_vm.title)+"\n                ")]),_vm._v(" "),(!_vm.sm && _vm.description)?_c('h5',{staticClass:"font-Inter-Regular",class:{ 'text-xs mb-8 leading-[1.1rem]': _vm.md, 'text-sm leading-[1.5]': !_vm.md && !_vm.sm }},[_vm._v("\n                    "+_vm._s(_vm.description)+"\n                ")]):_vm._e(),_vm._v(" "),(!_vm.sm && !_vm.md && !_vm.lg)?_c('div',{staticClass:"w-full text-blue-100 font-Inter-Bold text-sm mt-8 group mb-8 lg:mb-12",class:_vm.xl ? 'lg:text-right text-xl' : 'lg:text-right text-sm'},[_c('span',{staticClass:"group-hover:underline",class:_vm.xl ? 'text-sm lg:text-base' : 'text-sm'},[_vm._v("Tovább olvasom")]),_vm._v(" "),_c('i',{staticClass:"inline-block ri-arrow-right-line transition group-hover:translate-x-1",class:_vm.xl ? 'text-base' : 'text-sm'})]):_vm._e()]),_vm._v(" "),(_vm.publishDate)?_c('div',{staticClass:"text-black opacity-50",class:[_vm.sm ? 'text-[10px]' : 'text-xs', _vm.lg && 'mt-4']},[(!_vm.sm && !_vm.lg)?_c('span',[_vm._v(_vm._s(_vm.minutes)+" perc olvasás -  ")]):_vm._e(),_c('span',[_vm._v(_vm._s(_vm.publishDate))])]):_vm._e()])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }