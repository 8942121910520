<template>
    <nuxt-link v-if="to" :to="to">
        <div class="more-btn">
            {{ label }}
        </div>
    </nuxt-link>
    <button v-else class="more-btn" @click="cb">
        {{ label }}
    </button>
</template>

<script>
export default {
    name: 'MoreButton',
    props: {
        cb: {
            type: Function,
            required: false,
            default: () => {},
        },
        label: {
            type: String,
            required: false,
            default: 'Továbbiak megtekintése',
        },
        slug: {
            type: String,
            required: false,
            default: '/',
        },
        to: {
            type: String,
            required: false,
            default: '',
        },
    },
};
</script>

<style>
.more-btn {
    @apply text-center font-Inter-Bold flex items-center gap-x-3 hover:underline;

    &::before {
        content: '';
        @apply h-9 w-9 bg-no-repeat block bg-center bg-contain transition-all;
        background-image: url('~/assets/images/icons/plus-icon-blue.svg');
    }

    &:hover {
        &::before {
            @apply scale-105;
        }
    }
}
</style>
