<template>
    <div
        class="flex flex-wrap gap-[9px] items-start lg:items-center"
        :class="!isProductCard ? 'flex-row' : 'flex-col lg:flex-row'"
    >
        <div class="flex items-center gap-x-[9px]">
            <div
                v-for="i in 5"
                :key="`star-${i}`"
                class="star"
                :class="{
                    'star--active': i <= Math.floor(ratingValue),
                    'star--half-active': i === Math.ceil(ratingValue) && !Number.isInteger(ratingValue),
                    'star--inactive': i > Math.ceil(ratingValue),
                }"
            ></div>
        </div>
        <span v-if="reviewCount" class="font-Inter-Semibold text-xs opacity-50 leading-none"
            >{{ ratingValue.toFixed(1) }} ({{ reviewCount }} vélemény)</span
        >
    </div>
</template>

<script>
export default {
    name: 'Rating',
    props: {
        ratingValue: {
            type: Number,
            required: true,
        },
        reviewCount: {
            type: Number,
            required: false,
            default: 0,
        },
        isProductCard: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
};
</script>

<style scoped>
.star {
    @apply w-4 h-4 block bg-contain bg-center bg-no-repeat;
    &--active {
        background-image: url('~/assets/images/icons/active-star.svg');
    }
    &--inactive {
        background-image: url('~/assets/images/icons/inactive-star.svg');
    }
    &--half-active {
        background-image: url('~/assets/images/icons/half-active-star.svg');
    }
}
</style>
